<template>
  <div>
    <el-form
      :rules="rules"
      :model="receivedForm"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      
      <el-row>
        <el-col :span="12">
          <el-form-item label="Дата" prop="date">
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="receivedForm.date"
              type="date"
              @change="setDatastored"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col>
        <el-form-item  prop="ammount" label="" v-if="datastored !== null">
          Хранится на {{receivedForm.date}} в данном структурном подразделении: {{ datastored.amount-datastored.sumAmountGet }} {{ datastored.pss93UnitInfo.name }}
        </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="2">
          <el-form-item label="">
            <el-radio
              :value="false"
              v-model="receivedForm.receivedFromPerson"
              :label="false"
              @change="changeRuleCIR"
            >
              -</el-radio
            >
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="Контрагенты:" prop="contractorsInfoReceived" 
          
          > 
            <el-select
            
              v-model="receivedForm.contractorsInfoReceived"
              :disabled="receivedForm.receivedFromPerson"
              style="width: 100%"
              value-key="id"
              clearable
              filterable
              placeholder="Выберите"
            >
              <el-option
              
                v-for="item in contractorsList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="2">
          <el-form-item label="">
            <el-radio v-model="receivedForm.receivedFromPerson" :label="true"  @change="changeRuleCIR">
              -</el-radio
            >
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label="Физ. лица"> </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-form-item prop="pss17Info" label="Поступило для:">
            <el-select
              clearable
              class="searchPole"
              value-key="id"
              v-model="receivedForm.pss17Info"
              filterable
              placeholder="Наименование причины"
            >
              <el-option
                v-for="item in pss17ReceiptReasonInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Количество" prop="store">
            <el-input v-model="receivedForm.store"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Единица измерения">
            <el-input v-model="waste.pss93UnitInfo.name" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="Из них по импорту, количество" prop="number">
            <el-input v-model="receivedForm.importstore"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Единица измерения">
            <el-input v-model="waste.pss93UnitInfo.name" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row>
        <el-col :span="24">
          <el-button @click="save" style="float: right" type="primary" :disabled="block || (new Date(receivedForm.date.split('-')[0],receivedForm.date.split('-')[1]-1,receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (userId==469 || userId==470 || userId==471))  || (new Date(receivedForm.date.split('-')[0],receivedForm.date.split('-')[1]-1,receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (userId==482 || userId==501))  || (new Date(receivedForm.date.split('-')[0],receivedForm.date.split('-')[1]-1,receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (userId!=409   && userId!=265 && userId!=266 && userId!=267
&& userId!=268 && userId!=269 && userId!=270 && userId!=271
&& userId!=272 && userId!=273 && userId!=274 && userId!=275
&& userId!=276 && userId!=277 && userId!=278 && userId!=279
&& userId!=280 && userId!=281 && userId!=282 && userId!=283
&& userId!=284 && userId!=285 && userId!=286 && userId!=287
&& userId!=288 && userId!=289 && userId!=290  && userId!=233 && userId!=234 && userId!=25 && userId!=292 && userId!=245 && userId!=14 && userId!=465 && userId!=505 && userId!=506 && userId!=507 && userId!=428 && userId!=124 && userId!=464 && userId!=557 && userId!=559 )) "
            >Сохранить</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import notification from "@/mixins/notification";
import Cookies from "js-cookie";
export default {
  name: "receivedModal",
  props: ["waste"],
  mixins: [notification],
  data() {
    return {
      userId:Cookies.get('userId') ,
      datastored:null,
      pss17ReceiptReasonInfoList: null,
      actionId: 2,
      receivedForm: {
        amount: this.waste.amount,
        store: null,
        date: "",

        contractorsInfoReceived: null,
        pss17Info: null,
        importstore: 0,
        receivedFromPerson: false,
      },

      rules: {
        store: [
          { required: true, message: "Введите количество", trigger: "blur" },
        ],

        amountExport: [
          { required: true, message: "Введите количество", trigger: "blur" },
        ],

        measurement: [
          {
            required: true,
            message: "Укажите единицу измерения",
            trigger: "blur",
          },
        ],

        pss17Info: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
        contractorsInfoReceived: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],



        date: [{ required: true, message: "Введите дату", trigger: "blur" }],
      },
    };
  },

  computed: {
    ...mapGetters({
      contractorsList: "GETLISTCONTRACTORSSELECT",
    }),
  },

  methods: {
    changeRuleCIR()
    {
      this.rules.contractorsInfoReceived[0].required = !this.receivedForm.receivedFromPerson;
    },

    setDatastored(){
      if((new Date(this.receivedForm.date.split('-')[0],this.receivedForm.date.split('-')[1]-1,this.receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (this.userId==469 || this.userId==470 || this.userId==471)) || (new Date(this.receivedForm.date.split('-')[0],this.receivedForm.date.split('-')[1]-1,this.receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (this.userId==482 || this.userId==501)) ||(new Date(this.receivedForm.date.split('-')[0],this.receivedForm.date.split('-')[1]-1,this.receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=25 && this.userId!=292 && this.userId!=245 && this.userId!=14 && this.userId!=465 && this.userId!=505 && this.userId!=506 && this.userId!=507 && this.userId!=428 && this.userId!=124 && this.userId!=464 && this.userId!=557 && this.userId!=559 )))
        this.message("Внимание! Внесение данных за "+new Date(this.validat().item.date).getFullYear()+" год ограничено. Закрыт отчетный период", "error", true);
      this.getDatastored().then((response) => {
        if(response.data == true)
        {this.block=true;
          this.message("Внимание! Цепочка устарела!", "error", true);
          return;
        }
        if(response.data == false)
        {this.block=true;
          this.message("Внимание! Цепочка не начала действовать!", "error", true);
          return;
        }
        this.block=false;
          this.datastored = response.data;
      });
    },
    async getDatastored(){
    let data = {date:"",techProcessHasWasteId:""};
    data.date=this.receivedForm.date;
    data.techProcessHasWasteId = this.waste.techProcessHasWasteId;
    return await this.$store.dispatch(
                "getDataStored",
                data
              );
  },
    getPss17ReceiptReasonInfoList() {
      this.getSubClassifierById(17).then((response) => {
        console.log(response.data);

        this.pss17ReceiptReasonInfoList = response.data.content;
      });
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },
    validat(){
      let i = 0;
        if(this.receivedForm.date == "" || this.receivedForm.store== "")
        //{this.ErrorMasseg("Внимание! Заполните всте поля");
          i= 1;
        if((new Date(this.receivedForm.date.split('-')[0],this.receivedForm.date.split('-')[1]-1,this.receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (this.userId==469 || this.userId==470 || this.userId==471)) || (new Date(this.receivedForm.date.split('-')[0],this.receivedForm.date.split('-')[1]-1,this.receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (this.userId==482 || this.userId==501)) ||(new Date(this.receivedForm.date.split('-')[0],this.receivedForm.date.split('-')[1]-1,this.receivedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=25 && this.userId!=292 && this.userId!=245 && this.userId!=14 && this.userId!=465 && this.userId!=505 && this.userId!=506 && this.userId!=507 && this.userId!=428 && this.userId!=124 && this.userId!=464 && this.userId!=557 && this.userId!=559 )))
        //{this.ErrorMasseg("Внимание! Дата одного из значений меньше чем "+new Date().getFullYear()+" год");
          i= 2;
      return {id:i,item:this.receivedForm};
    },
    save: function () {
      switch (this.validat().id)
      {
        case 1: 
        this.ErrorMasseg("Внимание! Заполните все поля");
        return;
        case 2: 
        this.ErrorMasseg("Внимание! Внесение данных за "+new Date(this.validat().item.date).getFullYear()+" год ограничено. Закрыт отчетный период")
        return;
        case 0: break;
      }
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.receivedForm.amount = this.waste.amount;
          
          console.log("VALID", this.receivedForm);
          if (this.receivedForm.receivedFromPerson) {
            this.receivedForm.contractorsInfoReceived = null;
          }
          this.$store
            .dispatch("receiveDataEntryForm", {
              modalData: this.receivedForm,
              id: this.$route.params.processId,
              actionId: this.actionId,
            })
            .then(() => {
              this.$store.dispatch(
                "getMainPageForDataEntry",
                this.$route.params.processId
              );
              this.notification("Успешно", "Данные внесены", "success");
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("setShowModal", false);
            });
        } else {
          console.log("Не валид");
          console.log(this);
          return false;
        }
      });
    },
  },

  mounted() {
    this.$store.dispatch("getListContractors");
    this.getPss17ReceiptReasonInfoList();
  },
};
</script>

<style scoped></style>
