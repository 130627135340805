<template>
  <div>
    <div hidden>
      <h4>Доступно реестров для вывоза отхода:</h4>
      <h4>Доступно для захоронения</h4>
      <p>Максимальное значение: 0.0 шт. (штук)</p>
      <p>Текущее значение: 0.0 шт. (штук)</p>
      <h4 style="color: red">Заполненность: 100 %</h4>
    </div>

    <el-form
      :model="buriedForm"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >

      <el-row>
        <el-col :span="6">
          <el-form-item prop="date" label="Дата">
            <el-date-picker
              @change="setDatastored"
              value-format="yyyy-MM-dd"
              v-model="buriedForm.date"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item  prop="ammount" label="" v-if="datastored !== null">
            Доступно на {{buriedForm.date}} для проведения операции: {{ datastored.amount-datastored.sumAmountGet }} {{ datastored.pss93UnitInfo.name }}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-row>
          <el-form-item prop="store" label="Количество">
            <el-input v-model="buriedForm.store" ></el-input>
          </el-form-item>
          </el-row>
          
        </el-col>
        <el-col :span="6">
          <el-form-item label="Единица измерения">
            <el-input v-model="waste.pss93UnitInfo.name" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
            <p style="color: red" v-if="datastored !== null && parseFloat(buriedForm.store.toString().replace(',', '.')) > valueDatastor">Ошибка! Превышение доступного количества отхода</p>
          </el-row>


      <el-row>
        <el-col :span="24">
          <el-form-item prop="pss21Info" label="Объекты захоронения отходов">
            <el-select
              clearable
              placeholder="Объект захоронения"
              class="searchPole"
              value-key="id"
              v-model="buriedForm.pss21Info"
              filterable
            >
              <el-option
                v-for="item in pss21BurialObjectInfoList"
                :key="item.id"
                :label="item.name"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <el-button @click="passportAdd" type="primary" style="float: left" :disabled="parseFloat(buriedForm.store.toString().replace(',', '.')) > valueDatastor|| block || (new Date(buriedForm.date.split('-')[0],buriedForm.date.split('-')[1]-1,buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (userId==469 || userId==470 || userId==471)) || (new Date(buriedForm.date.split('-')[0],buriedForm.date.split('-')[1]-1,buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (userId==482 || userId==501)) || (new Date(buriedForm.date.split('-')[0],buriedForm.date.split('-')[1]-1,buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (userId!=409   && userId!=265 && userId!=266 && userId!=267
&& userId!=268 && userId!=269 && userId!=270 && userId!=271
&& userId!=272 && userId!=273 && userId!=274 && userId!=275
&& userId!=276 && userId!=277 && userId!=278 && userId!=279
&& userId!=280 && userId!=281 && userId!=282 && userId!=283
&& userId!=284 && userId!=285 && userId!=286 && userId!=287
&& userId!=288 && userId!=289 && userId!=290  && userId!=233 && userId!=234 && userId!=25 && userId!=292 && userId!=245 && userId!=14 && userId!=465 && userId!=505 && userId!=506 && userId!=507 && userId!=428 && userId!=124 && userId!=464 && userId!=557 && userId!=559 ))"
            >Сопроводительный паспорт</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button @click="save" type="primary" style="float: right" :disabled="parseFloat(buriedForm.store.toString().replace(',', '.')) > valueDatastor|| block || (new Date(buriedForm.date.split('-')[0],buriedForm.date.split('-')[1]-1,buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (userId==469 || userId==470 || userId==471))  || (new Date(buriedForm.date.split('-')[0],buriedForm.date.split('-')[1]-1,buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (userId==482 || userId==501))  || (new Date(buriedForm.date.split('-')[0],buriedForm.date.split('-')[1]-1,buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (userId!=409   && userId!=265 && userId!=266 && userId!=267
&& userId!=268 && userId!=269 && userId!=270 && userId!=271
&& userId!=272 && userId!=273 && userId!=274 && userId!=275
&& userId!=276 && userId!=277 && userId!=278 && userId!=279
&& userId!=280 && userId!=281 && userId!=282 && userId!=283
&& userId!=284 && userId!=285 && userId!=286 && userId!=287
&& userId!=288 && userId!=289 && userId!=290  && userId!=233 && userId!=234 && userId!=25 && userId!=292 && userId!=245 && userId!=14 && userId!=465 && userId!=505 && userId!=506 && userId!=507 && userId!=428 && userId!=124 && userId!=464 && userId!=557 && userId!=559 ))"
            >Сохранить</el-button
          >
        </el-col>
      </el-row>
    </el-form>

    <custom-modal
      :visible="showModal === 'dataPasport'"
      
    >
      <disposal-pasport-form
        :action="passport" :waste="wastes"
        @addPasportToTable="addPasportToTable"
      ></disposal-pasport-form>
    </custom-modal>
  </div>
</template>

<script>
import notification from "@/mixins/notification";
import Cookies from "js-cookie";
import CustomModal from "@/components/customModal";
import { mapGetters } from "vuex";
import disposalPasportForm from "@/components/ecologist/disposal/disposalPasport";
import { AXIOS } from "@/AXIOS/http-common";
export default {
  name: "buriedModal",
  components: { disposalPasportForm, CustomModal },
  props: ["waste"],
  mixins: [notification],
  data() {
    return {
      passport: {number:null,contractor1:null,contractor2:{content:0},contractor3:null,data:null,
      vehicle:null,full_name:null,tara_name:null,tara_vight:null,vehicle_vight:null,number_card:null,
      owner:null,ownerr:null,
      transfer:null,transferr:null,
    ca1:'Ручной ввод',contractor1r:null,
    ca2:'Ручной ввод',contractor2r:null,
    ca3:'Ручной ввод',contractor3r:null,
    egr_full_name:null,
    chek_null_value:false,
    fileType:".pdf",
    modal:"dataEntry",
    tr:'Ответственное лицо',ow:'Ответственное лицо',
    rules: {
      rul: [{required: true, message: "Заполните поле", trigger: "blur"}],
    }
  },
      wastes:null,
      userId:Cookies.get('userId') ,
      block: false,
      valueDatastor:0,
      dateDatastore: null,
      pss21BurialObjectInfoList: null,
      datastored:null,
      actionId: 6,
      buriedForm: {
        amount: this.waste.amount,
        store: "0",
        date: "",
        pss21Info: null,
      },
      rules: {
        pss21Info: [
          { required: true, message: "Заполните поле", trigger: "blur" },
        ],

        store: [{ required: true, message: "Заполните поле", trigger: "blur"}],

        date: [{ required: true, message: "Введите дату", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
      listContractors: "GETLISTCONTRACTORS",
      NumberPassport: "GETDATASTORED",
    }),
  },

  methods: {
    getPss21BurialObjectInfoList() {
      this.getSubClassifierById(21).then((response) => {
        this.pss21BurialObjectInfoList = response.data.content;
      });
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },
    setDatastored(){

      if((new Date(this.buriedForm.date.split('-')[0],this.buriedForm.date.split('-')[1]-1,this.buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (this.userId==469 || this.userId==470 || this.userId==471)) || (new Date(this.buriedForm.date.split('-')[0],this.buriedForm.date.split('-')[1]-1,this.buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (this.userId==482 || this.userId==501)) || (new Date(this.buriedForm.date.split('-')[0],this.buriedForm.date.split('-')[1]-1,this.buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=25 && this.userId!=292 && this.userId!=245 && this.userId!=14 && this.userId!=465 && this.userId!=505 && this.userId!=506 && this.userId!=507 && this.userId!=428 && this.userId!=124 && this.userId!=464 && this.userId!=557 && this.userId!=559 )))
        this.message("Внимание! Внесение данных за "+new Date(this.validat().item.date).getFullYear()+" год ограничено. Закрыт отчетный период", "error", true);
      this.getDatastored().then((response) => {
        if(response.data == true)
        {this.block=true;
          this.message("Внимание! Цепочка устарела!", "error", true);
          return;
        }
        if(response.data == false)
        {this.block=true;
          this.message("Внимание! Цепочка не начала действовать!", "error", true);
          return;
        }
        this.block=false;
        this.datastored = response.data;
      this.valueDatastor = this.datastored.amount-this.datastored.sumAmountGet;
      this.dateDatastore = Date.parse(response.data.path);
      if(this.dateDatastore > Date.parse(this.buriedForm.date,'yyyy-MM-dd'))
      this.message("Внимание! Внесение информации на данной вкладке приведет к перерасчету количества отходов и изменениям в отчетной документации. Будьте внимательны при заполнении!", "error", true);
        
    });
    },
    async getDatastored(){
      let data = {date:"",techProcessHasWasteId:""};
      data.date=this.buriedForm.date;
      data.techProcessHasWasteId = this.waste.techProcessHasWasteId;
      return await this.$store.dispatch(
                "getDataStored",
                data
              );
    },
    validat(){
      let i = 0;
        if(this.buriedForm.date == "" || this.buriedForm.store== "")
        //{this.ErrorMasseg("Внимание! Заполните всте поля");
          i= 1;
        if((new Date(this.buriedForm.date.split('-')[0],this.buriedForm.date.split('-')[1]-1,this.buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),1,1) && (this.userId==469 || this.userId==470 || this.userId==471))  || (new Date(this.buriedForm.date.split('-')[0],this.buriedForm.date.split('-')[1]-1,this.buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),2,1) && (this.userId==482 || this.userId==501)) ||(new Date(this.buriedForm.date.split('-')[0],this.buriedForm.date.split('-')[1]-1,this.buriedForm.date.split('-')[2])<new Date(new Date().getFullYear(),0,1) && (this.userId!=409   && this.userId!=265 && this.userId!=266 && this.userId!=267
&& this.userId!=268 && this.userId!=269 && this.userId!=270 && this.userId!=271
&& this.userId!=272 && this.userId!=273 && this.userId!=274 && this.userId!=275
&& this.userId!=276 && this.userId!=277 && this.userId!=278 && this.userId!=279
&& this.userId!=280 && this.userId!=281 && this.userId!=282 && this.userId!=283
&& this.userId!=284 && this.userId!=285 && this.userId!=286 && this.userId!=287
&& this.userId!=288 && this.userId!=289 && this.userId!=290  && this.userId!=233 && this.userId!=234 && this.userId!=25 && this.userId!=292 && this.userId!=245 && this.userId!=14 && this.userId!=465 && this.userId!=505 && this.userId!=506 && this.userId!=507 && this.userId!=428 && this.userId!=124 && this.userId!=464 && this.userId!=557 && this.userId!=559 )))
        //{this.ErrorMasseg("Внимание! Дата одного из значений меньше чем "+new Date().getFullYear()+" год");
          i= 2;
      return {id:i,item:this.buriedForm};
    },
    async GetNumberPassport() {
     return await AXIOS.get(
      "report/get/NumberPass"
    ).then((response) => {
    this.passport.number = response.data.numb;
    this.passport.egr_full_name = response.data.egr_full_name;
    this.passport.contractor1r = response.data.egr_full_name;
    this.passport.contractor2r = response.data.egr_full_name;
    this.passport.contractor3r = response.data.egr_full_name;

    });
  },
  async passportAdd() {
      await this.GetNumberPassport();
      this.passport.data = this.buriedForm.date.split('-')[2]+"."+this.buriedForm.date.split('-')[1]+"."+this.buriedForm.date.split('-')[0]
      //if (item.Contractors !== undefined)
      this.$store.dispatch("setShowModal", "dataPasport");
    },
    addPasportToTable(item)
    {
      console.log(this.showModal);
      this.passport=item;
      this.buriedForm.passport=item;//.action;
      //this.wastes[0]=item.wastes;
      this.save();
    },
    save() {
      switch (this.validat().id)
      {
        case 1: 
        this.ErrorMasseg("Внимание! Заполните все поля");
        return;
        case 2: 
        this.ErrorMasseg("Внимание! Внесение данных за "+new Date(this.validat().item.date).getFullYear()+" год ограничено. Закрыт отчетный период")
        return;
        case 0: break;
      }
      this.$refs["formedForm"].validate((valid) => {
        if (valid) {
          this.buriedForm.amount = this.waste.amount;
          console.log("VALID", this.buriedForm);
          this.$store
            .dispatch("receiveDataEntryForm", {
              modalData: this.buriedForm,
              id: this.$route.params.processId,
              actionId: this.actionId,
            })
            .then(() => {
              this.$store.dispatch(
                "getMainPageForDataEntry",
                this.$route.params.processId
              );
              this.notification("Успешно", "Данные внесены", "success");
              this.$store.dispatch("setShowModal", null);
              this.$store.dispatch("setShowModal", false);
            });
        } else {
          return false;
        }
      });
    },
  },

  mounted() {
    this.getPss21BurialObjectInfoList();
  },
};
</script>

<style scoped></style>
