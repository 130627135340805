<template>
  <div>
    <h2>Внесение данных</h2>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="12"
        ><p>
          Природопользователь: <b> {{ authUser.organization.name }} </b>
        </p></el-col
      >
      <el-col :span="12"
        ><p>
          Филиал / Подразделение / Участок: <b> {{ dataEntryPage.path }}</b>
        </p></el-col
      >
    </el-row>

    <el-row>
      <el-col :span="12"
        ><p>
          Технологический процесс: <b> {{ dataEntryPage.processName }} </b>
        </p></el-col
      >
      <el-col :span="12"
        ><p>
          Отход:
          <b> {{ dataEntryPage.wasteInfo.name }}</b>
        </p></el-col
      >
    </el-row>

    <el-row>
      <el-col :span="12">
        <el-row>
          Степень и класс опасности:
          <b>
            {{
              dataEntryPage.ss01DangerDegreeInfo.name !== null
                ? dataEntryPage.ss01DangerDegreeInfo.name
                : "Сведения отсутствуют"
            }}</b>
        </el-row>
        <br>
        <el-row style="color:red" v-if="dataEntryPage.sumAmount >= dataEntryPage.storeLimit && dataEntryPage.storeLimit>0">
          Превышено количество данного вида отхода  для временного хранения (одна транспортная единица) на:
          <b>{{ Math.round((dataEntryPage.sumAmount-dataEntryPage.storeLimit)*1000)/1000}} {{ dataEntryPage.pss93UnitInfo.name }}</b>
        </el-row>
        <el-row style="color:red" v-if="dataEntryPage.sumAmount < dataEntryPage.storeLimit && dataEntryPage.sumAmount > dataEntryPage.storeLimit * 0.9 && dataEntryPage.storeLimit>0">
          Внимание! До накопления одной транспортной единицы осталось: 
          <b>{{ Math.round((dataEntryPage.storeLimit-dataEntryPage.sumAmount)*1000)/1000}} {{ dataEntryPage.pss93UnitInfo.name }}</b>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row>
          Хранится на текущий момент:
          <b>{{ dataEntryPage.amount }} {{ dataEntryPage.pss93UnitInfo.name }}</b>
        </el-row>
        <el-row v-if="dataEntryPage.organizationWasteStoreLicense !== null || parseFloat(dataEntryPage.organizationWasteStoreLimit) !== -1">
          &#40;Из них направлено на хранение:&#41; <b>
            {{ Math.round(dataEntryPage.sumAmountGet*100000)/100000 }} {{ dataEntryPage.pss93UnitInfo.name }}</b>
        </el-row>
        <br>
        <el-row style="color:red" v-for="item in arrayLimit" :key="item.id">
          {{ item.name }} {{ dataEntryPage.pss93UnitInfo.name }}
        </el-row>
      </el-col>
    </el-row>

    <el-divider></el-divider>

    <el-row :gutter="12">
      <el-col :span="6">
        <el-button
        :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined"

          @click="modalOpen('formed', 'Образовалось')"
          type="primary"
          plain
          style="width: 100%"
          >Образовалось</el-button
        >
      </el-col>
      <el-col :span="6">
        <el-button
        :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined"
          @click="modalOpen('received', 'Поступило от')"
          type="primary"
          plain
          style="width: 100%"
          >Поступило от</el-button
        >
      </el-col>
    </el-row>
    <br />

    <el-row :gutter="12">
      <el-col :span="6">
        <el-button
          :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined || Math.round(dataEntryPage.amount*100000)/100000-Math.round(dataEntryPage.sumAmountGet*100000)/100000<=0"
          @click="modalOpen('transferred', 'Передано')"
          type="primary"
          plain
          style="width: 100%"
          >Передано</el-button
        >
      </el-col>
      <el-col :span="6">
        <el-button
          :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined || Math.round(dataEntryPage.amount*100000)/100000-Math.round(dataEntryPage.sumAmountGet*100000)/100000<=0"
          @click="modalOpen('renderedHarmless', 'Обезврежено')"
          type="primary"
          plain
          style="width: 100%"
          >Обезврежено</el-button
        >
      </el-col>
    </el-row>
    <br />

    <el-row :gutter="12">
      <el-col :span="6">
        <el-button
          :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined || Math.round(dataEntryPage.amount*100000)/100000-Math.round(dataEntryPage.sumAmountGet*100000)/100000<=0"
          @click="modalOpen('used', 'Использовано')"
          type="primary"
          plain
          style="width: 100%"
          >Использовано</el-button
        >
      </el-col>
      <el-col :span="6">
        <el-button
          :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined || Math.round(dataEntryPage.amount*100000)/100000-Math.round(dataEntryPage.sumAmountGet*100000)/100000<=0"
          v-if="dataEntryPage.organizationWasteBurialLicense !== null"
          @click="modalOpen('buried', 'Захоронено')"
          type="primary"
          plain
          style="width: 100%"
          >Захоронено</el-button
        >
      </el-col>
    </el-row>
    <br />

    <el-row :gutter="12">
      <el-col :span="12">
        <el-button
        :disabled="tphwInclude.find(el=>el==dataEntryPage.techProcessHasWasteId)!=undefined || Math.round(dataEntryPage.amount*100000)/100000-Math.round(dataEntryPage.sumAmountGet*100000)/100000<=0"
          v-if="
            dataEntryPage.organizationWasteStoreLicense !== null ||
            parseFloat(dataEntryPage.organizationWasteStoreLimit) !== -1
          "
          @click="modalOpen('inStorage', 'Направлено на объект хранения')"
          type="primary"
          plain
          style="width: 100%"
          >Направлено на объект хранения</el-button
        >
      </el-col>
    </el-row>
    <br />
    <!--    <el-button type="primary">На согласование</el-button>-->

    <custom-modal
      destroy-on-close
      :title="modalTitle"
      :visible="showModal === 'dataEntry' || showModal === 'dataPasport'"
      width="70%"
    >
      <buried-modal
        :waste="dataEntryPage"
        v-if="modalType === 'buried'"
      ></buried-modal>

      <formed-modal
        :waste="dataEntryPage"
        v-if="modalType === 'formed'"
      ></formed-modal>

      <in-storage-modal
        :waste="dataEntryPage"
        v-if="modalType === 'inStorage'"
      ></in-storage-modal>

      <received-modal
        :waste="dataEntryPage"
        v-if="modalType === 'received'"
      ></received-modal>

      <rendered-harmless-modal
        :waste="dataEntryPage"
        v-if="modalType === 'renderedHarmless'"
      ></rendered-harmless-modal>

      <transferred-modal
        :waste="dataEntryPage"
        v-if="modalType === 'transferred'"
      ></transferred-modal>

      <used-modal
        :waste="dataEntryPage"
        v-if="modalType === 'used'"
      ></used-modal>
    </custom-modal>
  </div>
</template>

<script>
import BuriedModal from "@/components/ecologist/dataEntry/modals/buriedModal";
import FormedModal from "@/components/ecologist/dataEntry/modals/formedModal";
import ReceivedModal from "@/components/ecologist/dataEntry/modals/receivedModal";
import InStorageModal from "@/components/ecologist/dataEntry/modals/inStorageModal";
import RenderedHarmlessModal from "@/components/ecologist/dataEntry/modals/renderedHarmlessModal";
import TransferredModal from "@/components/ecologist/dataEntry/modals/transferredModal";
import UsedModal from "@/components/ecologist/dataEntry/modals/usedModal";
import { mapGetters } from "vuex";
import CustomModal from "@/components/customModal";
export default {
  name: "dataEntryEdit",
  components: {
    CustomModal,
    UsedModal,
    TransferredModal,
    RenderedHarmlessModal,
    InStorageModal,
    ReceivedModal,
    FormedModal,
    BuriedModal,
  },
  data() {
    return {
      tphwInclude:[],
      dialogVisible: false,
      modalType: "",
      modalTitle: "",
      arrayLimit:[],
    };
  },

  computed: {
    ...mapGetters({
      dataEntryPage: "GETDATAENTRYMAINPAGE",
      authUser: "GETAUTHUSER",
      showModal: "GETSHOWMODAL",
    }),
  },

  methods: {
    modalOpen(type, title) {
      this.$store.dispatch("setShowModal", "dataEntry");
      this.dialogVisible = true;
      this.modalType = type;
      this.modalTitle = title;
    },
  },

  async mounted() { 
    await this.$store.dispatch(
      "getMainPageForDataEntry",
      this.$route.params.processId
    );
    await this.$store.dispatch("checkStoreValidityDatehranOnliSum",{wasteId:this.$route.params.wasteId,processId:this.$route.params.processId}).then(async (response) => {
      this.arrayLimit=this.arrayLimit.concat(response.data);
      await this.$store.dispatch("checkStoreValidityDatezahranOnliSum",{wasteId:this.$route.params.wasteId,processId:this.$route.params.processId}).then(async (response) => {
        this.arrayLimit=this.arrayLimit.concat(response.data);
      })
    });
    
  },
};
</script>

<style scoped></style>
